import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, ThemeProvider, createMuiTheme, Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles, Box } from '@material-ui/core';

import NavBar from './components/NavBar';
import Homepage from './components/Homepage';
import About from './components/About';
import './App.css';

function App() {
    // We keep the theme in app state
    const [theme, setTheme] = useState({
        palette: {
            type: 'dark',
        },
    });
    const [state, setState] = React.useState({
        checkedA: true,
    });

    // we change the palette type of the theme in state
    const toggleDarkTheme = () => {
        const newPaletteType = theme.palette.type === 'light' ? 'dark' : 'light';
        setTheme({
            palette: {
                type: newPaletteType,
            },
        });
    };

    // we generate a MUI-theme from state's theme object
    const muiTheme = createMuiTheme(theme as Theme);

    return (
        <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <Router>
                <ThemeProvider
                    theme={(theme: Theme) =>
                        createMuiTheme({
                            ...theme,
                            palette: {
                                ...theme.palette,
                                primary: {
                                    main: '#1a1a1a',
                                },
                                secondary: {
                                    main: '#bada55',
                                },
                            },
                            overrides: {
                                MuiPaper: {
                                    root: { backgroundColor: 'inherit' },
                                },
                                MuiButton: {
                                    root: {
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                        },
                                    },
                                    label: {
                                        transition: '500ms',
                                        borderBottom: '2px solid transparent',
                                        '&:hover': {
                                            borderColor: 'inherit',
                                        },
                                    },
                                },
                            },
                        })
                    }
                >
                    <NavBar onToggleDark={toggleDarkTheme}></NavBar>
                </ThemeProvider>

                <Box>
                    {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                    <Switch>
                        <Route path="/about">
                            <About />
                        </Route>
                        <Route path="/">
                            <Homepage />
                        </Route>
                    </Switch>
                </Box>
            </Router>
        </ThemeProvider>
    );
}

export default App;
