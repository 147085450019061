import React from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, useTheme, Theme, Box, Switch as SwitchButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
        },
        root: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        title: {
            flexGrow: 1,
        },
    }),
);

const NavBar = ({ onToggleDark }: NavBarProps) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        checkedA: false,
    });
    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [name]: event.target.checked });
        onToggleDark();
    };
    return (
        <AppBar position="static" elevation={0} color="inherit" style={{ marginBottom: 32 }}>
            <Toolbar className={classes.root}>
                <Box>
                    <Button component={Link} to="/" className={classes.button}>
                        Home
                    </Button>
                    <Button component={Link} to="/about" className={classes.button}>
                        About
                    </Button>
                </Box>
                <SwitchButton
                    color="primary"
                    checked={state.checkedA}
                    onChange={handleChange('checkedA')}
                    value="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </Toolbar>
        </AppBar>
    );
};
type NavBarProps = { onToggleDark: any };
export default NavBar;
