import React from 'react';
import { createStyles, makeStyles, useTheme, Theme, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        centerText: {
            // textAlign: 'center',
        },
    }),
);

const About = ({}: AboutProps) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Box
            style={{
                width: '60%',
                height: '60vh',
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <Typography>Hi my name is Erik and I am currently a React frontend developer.</Typography>
            <Typography>
                {/* <Typography variant="body1" className={classes.centerText}> */}
                This website is a playground for me to test out new things, showcase some stuff and maybe in the future
                provide some useful information in form of a blog.
                {/* </Typography> */}
            </Typography>
        </Box>
    );
};
type AboutProps = {};
export default About;
