import React from 'react';
import { createStyles, makeStyles, useTheme, Theme, Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import portrait from '../Portrait_Erik_Zocher.jpg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        centerText: {
            textAlign: 'center',
        },
    }),
);

const Homepage = ({}: HomepageProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const [state, setState] = React.useState({
        checkedA: true,
    });
    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [name]: event.target.checked });
    };
    return (
        <>
            <Grid
                container
                spacing={3}
                style={{
                    margin: 'auto',
                    textAlign: 'center',
                }}
            >
                <Grid item xs={12}>
                    <img src={portrait} className="App-logo" alt="logo" style={{ borderRadius: '50%' }} />
                    <Box component="p" className={classes.centerText}>
                        <Typography>
                            <code>Hello World!</code>
                        </Typography>
                    </Box>
                    <Box component="p" className={classes.centerText}>
                        <Typography>
                            <a
                                className="App-link"
                                href="https://github.com/frometor"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://github.com/frometor
                            </a>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
type HomepageProps = {};
export default Homepage;
